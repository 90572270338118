// ----------------------------------------------------------------------

export const title = [
    "Apply These 7 Secret Techniques To Improve Event",
    "Believing These 7 Myths About Event Keeps You From Growing",
    "Don't Waste Time! 7 Facts Until You Reach Your Event",
    "How 7 Things Will Change The Way You Approach Event",
    "Event Awards: 7 Reasons Why They Don't Work & What You Can Do About It",
    "Event Doesn't Have To Be Hard. Read These 7 Tips",
    "Event Is Your Worst Enemy. 7 Ways To Defeat It",
    "Event On A Budget: 7 Tips From The Great Depression",
    "Knowing These 7 Secrets Will Make Your Event Look Amazing",
    "Master The Art Of Event With These 7 Tips",
    "My Life, My Job, My Career: How 7 Simple Event Helped Me Succeed",
    "Take Advantage Of Event - Read These 7 Tips",
    "The Next 7 Things You Should Do For Event Success",
    "The Time Is Running Out! Think About These 7 Ways To Change Your Event",
    "The 7 Best Things About Event",
    "The 7 Biggest Event Mistakes You Can Easily Avoid",
    "The 7 Most Successful Event Companies In Region",
    "Think Your Event Is Safe? 7 Ways You Can Lose It Today",
    "Thinking About Event? 7 Reasons Why It's Time To Stop!",
    "7 Places To Get Deals On Event",
    "Best Event Android Apps",
    "Best Event Tips You Will Read This Year",
    "Best 30 Tips For Event",
    "Should Fixing Event Take 30 Steps?",
    "The A - Z Of Event",
    "The Next 30 Things To Immediately Do About Event",
    "The Ultimate Guide To Event",
    "Top 30 Funny Event Quotes",
    "Top 30 Quotes On Event",
    "Top 7 Lessons About Event To Learn Before You Hit 30",
    "Top 7 Ways To Buy A Used Event",
    "30 Best Ways To Sell Event",
    "30 Ideas For Event",
    "30 Lessons About Event You Need To Learn Before You Hit 40",
    "30 Methods Of Event Domination",
    "30 Things To Do Immediately About Event",
    "30 Tips To Grow Your Event",
    "30 Ways To Avoid Event Burnout",
    "30 Ways To Improve Event",
    "How To Make More Event By Doing Less",
]

export const sentence = [
    "Assumenda nam repudiandae rerum fugiat vel maxime.",
    "Quis veniam aut saepe aliquid nulla.",
    "Reprehenderit ut voluptas sapiente ratione nostrum est.",
    "Error ut sit vel molestias velit.",
    "Quo quia sit nihil nemo doloremque et.",
    "Autem doloribus harum vero laborum.",
    "Tempora officiis consequuntur architecto nostrum autem nam adipisci.",
    "Voluptas sunt magni adipisci praesentium saepe.",
    "Ea architecto quas voluptates voluptas earum illo est vel rem.",
    "Ipsum expedita reiciendis ut.",
    "Architecto vel voluptatibus alias a aut non maxime ipsa voluptates.",
    "Reiciendis enim officiis cupiditate eaque distinctio laudantium modi similique consequatur.",
    "Ab autem consequatur itaque mollitia ipsum cupiditate error repudiandae nobis.",
    "Distinctio architecto debitis eligendi consequatur unde id modi accusantium possimus.",
    "At ut voluptate accusantium.",
    "Repudiandae ut qui veritatis sint.",
    "Laboriosam blanditiis quo sed et qui esse ipsam necessitatibus sed.",
    "Et molestiae et excepturi maxime omnis.",
    "Sint dolorem quam eum magnam.",
    "Rerum ut iusto iste quam voluptatem necessitatibus.",
    "Et quam in.",
    "Fugit esse tenetur.",
    "Dolorem dolor porro nihil cupiditate molestiae deserunt ut.",
    "Omnis beatae eos eius aut molestias laboriosam laborum et optio.",
    "Ut veniam quam assumenda ut voluptatibus ducimus accusamus.",
    "Quos dignissimos neque omnis reiciendis voluptatem ducimus.",
    "Laboriosam quia ut esse.",
    "Sit reiciendis nihil qui molestias et.",
    "Facilis cupiditate minima ratione quaerat omnis velit non ex totam.",
    "Provident sint esse voluptatem voluptas eveniet est.",
    "Molestias consequatur ea facilis.",
    "Tempora voluptatibus autem ut ut porro quae delectus dolorum.",
    "Et consequatur amet nemo ducimus voluptatem placeat voluptas.",
    "Modi iste atque hic voluptas sit quis deleniti quas consequatur.",
    "Omnis est aliquid odio mollitia aliquid ex.",
    "Officia possimus veniam quod molestias.",
    "Mollitia inventore recusandae provident aut.",
    "Numquam ullam beatae possimus.",
    "Fuga velit cupiditate ex culpa odio aut ut.",
    "Vero corrupti nam voluptatum excepturi est et.",
]

export const description = [
    "Occaecati est et illo quibusdam accusamus qui. Incidunt aut et molestiae ut facere aut. Est quidem iusto praesentium excepturi harum nihil tenetur facilis. Ut omnis voluptates nihil accusantium doloribus eaque debitis.",
    "Atque eaque ducimus minima distinctio velit. Laborum et veniam officiis. Delectus ex saepe hic id laboriosam officia. Odit nostrum qui illum saepe debitis ullam. Laudantium beatae modi fugit ut. Dolores consequatur beatae nihil voluptates rem maiores.",
    "Rerum eius velit dolores. Explicabo ad nemo quibusdam. Voluptatem eum suscipit et ipsum et consequatur aperiam quia. Rerum nulla sequi recusandae illum velit quia quas. Et error laborum maiores cupiditate occaecati.",
    "Et non omnis qui. Qui sunt deserunt dolorem aut velit cumque adipisci aut enim. Nihil quis quisquam nesciunt dicta nobis ab aperiam dolorem repellat. Voluptates non blanditiis. Error et tenetur iste soluta cupiditate ratione perspiciatis et. Quibusdam aliquid nam sunt et quisquam non esse.",
    "Nihil ea sunt facilis praesentium atque. Ab animi alias sequi molestias aut velit ea. Sed possimus eos. Et est aliquid est voluptatem.",
    "Non rerum modi. Accusamus voluptatem odit nihil in. Quidem et iusto numquam veniam culpa aperiam odio aut enim. Quae vel dolores. Pariatur est culpa veritatis aut dolorem.",
    "Est enim et sit non impedit aperiam cumque animi. Aut eius impedit saepe blanditiis. Totam molestias magnam minima fugiat.",
    "Unde a inventore et. Sed esse ut. Atque ducimus quibusdam fuga quas id qui fuga.",
    "Eaque natus adipisci soluta nostrum dolorem. Nesciunt ipsum molestias ut aliquid natus ut omnis qui fugiat. Dolor et rem. Ut neque voluptatem blanditiis quasi ullam deleniti.",
    "Nam et error exercitationem qui voluptate optio. Officia omnis qui accusantium ipsam qui. Quia sequi nulla perspiciatis optio vero omnis maxime omnis ipsum. Perspiciatis consequuntur asperiores veniam dolores.",
    "Perspiciatis nulla ut ut ut voluptates totam consectetur eligendi qui. Optio ut cum. Dolorum sapiente qui laborum. Impedit temporibus totam delectus nihil. Voluptatem corrupti rem.",
    "Distinctio omnis similique omnis eos. Repellat cumque rerum nisi. Reiciendis soluta non ut veniam temporibus. Accusantium et dolorem voluptas harum. Nemo eius voluptate dicta et hic nemo. Dolorem assumenda et beatae molestias sit quo mollitia quis consequatur.",
    "Sed ut mollitia tempore ipsam et illum doloribus ut. Occaecati ratione veritatis explicabo. Omnis nam omnis sunt placeat tempore accusantium placeat distinctio velit.",
    "Eum illo dicta et perspiciatis ut blanditiis eos sequi. Ea veritatis aut et voluptas aut. Laborum eos quia tempore a culpa.",
    "Aut quos quae dolores repudiandae similique perferendis perferendis earum laudantium. Facere placeat natus nobis. Eius vitae ullam dolorem.",
    "Vero dolorem et voluptatem fugit tempore a quam iure. Fuga consequatur corrupti sunt asperiores vitae. Libero totam repellendus animi debitis illum et sunt officia.",
    "Cupiditate illum officiis id molestiae. Numquam non molestiae aliquid et natus sed hic. Alias quia explicabo sed corrupti sint. Natus in et odio qui unde facilis quia. Est sit eius laboriosam aliquid non aperiam quia quo corporis.",
    "Et a ab. Optio aspernatur minus tempora amet vitae consectetur inventore cumque. Sed et omnis. Aspernatur a magnam.",
    "Ipsum omnis et. Quia ea et autem tempore consequuntur veniam dolorem officiis. Ipsa dicta et ut quidem quia doloremque. Sequi vitae doloremque temporibus. Deserunt incidunt id aperiam itaque natus. Earum sit eaque quas incidunt nihil.",
    "Quae consequatur reiciendis. Consequatur non optio. Eaque id placeat. Commodi quo officia aut repudiandae reiciendis tempore voluptatem et. Ut accusamus qui itaque maxime aliquam. Fugit ut animi molestiae porro maiores.",
    "Modi hic asperiores ab cumque quam est aut. Voluptas atque quos molestias. Ut excepturi distinctio ipsam aspernatur sit.",
    "Sunt totam facilis. Quam commodi voluptatem veniam. Tempora deleniti itaque fugit nihil voluptas.",
    "Ipsam aliquam velit nobis repellendus officiis aut deserunt id et. Nihil sunt aut dolores aut. Dolores est ipsa quia et laborum quidem laborum accusamus id. Facilis odit quod hic laudantium saepe omnis nisi in sint. Sed cupiditate possimus id.",
    "Magnam non eveniet optio optio ut aliquid atque. Velit libero aspernatur quis laborum consequatur laudantium. Tempora facere optio fugit accusantium ut. Omnis aspernatur reprehenderit autem esse ut ut enim voluptatibus.",
    "Ipsam vel molestiae dolorem iure molestiae. Ut qui cumque et sint recusandae modi nulla. Vel rerum tempore similique autem enim voluptatem dolores facilis. Qui delectus recusandae magnam.",
    "Fugiat molestias distinctio enim nobis rerum. Perspiciatis adipisci corrupti quas sed ab sunt nostrum. Quibusdam reiciendis ratione dolores vitae fuga exercitationem asperiores maxime voluptate. Minus et ea delectus quia ullam est. Exercitationem iusto libero. Et aut dolore reprehenderit et saepe sint modi.",
    "Ex neque aut voluptatem delectus eum deserunt voluptate. Ut quam placeat et. Et impedit tenetur illum aut consequatur quia. Autem sed dolorem non ad aspernatur illum dignissimos beatae. Earum iusto aut rem aut. Facere ea alias enim quo assumenda.",
    "Quis sint recusandae quasi corporis et fugit. Omnis voluptatum id laborum qui. Assumenda animi quia eum et facere fuga. Ab et sint molestiae et voluptatum nostrum est.",
    "Est quis numquam recusandae alias porro magni. Incidunt quis aut necessitatibus nam ea dolores cumque. Quis dolorum veniam.",
    "Corporis magnam non. Ut voluptates pariatur et. Quis tenetur mollitia et necessitatibus et. Perferendis error velit aut.",
    "Eos ex accusantium possimus aut quae. Omnis dolorum velit. Sapiente quia dolore ea assumenda voluptatem exercitationem sed consequuntur. Eveniet et molestiae ipsa harum quidem soluta quo. Consequatur ad sunt sed numquam odio eveniet.",
    "Ut sit et provident vero vero voluptatem distinctio. Cumque sit error qui et. Ea architecto ipsum occaecati sed alias eum vel. Officia tempore architecto autem vel veniam. Minus modi commodi ad consequatur similique ut. Tenetur tempore eum.",
    "Quibusdam in cum voluptatem consequatur rerum. Qui eum assumenda nemo maiores veniam quae. Ipsa occaecati et provident. Quas similique deserunt doloremque placeat natus.",
    "Voluptates et earum fugiat molestiae distinctio. Aut sapiente quas dolores et. Quo et dolor velit et iure consequatur ut in amet. Repellendus aut sequi animi inventore nesciunt itaque.",
    "Sit a tempore dicta provident molestiae. Necessitatibus blanditiis voluptatum. Magnam esse animi in qui veritatis quibusdam recusandae adipisci possimus. Tenetur dolores ipsum.",
    "Neque id sunt amet modi expedita aut libero aut in. Minima dolore praesentium quia quas et pariatur numquam. In ut sequi nemo velit iste minima aliquam. Neque ut tenetur consequuntur sint nemo unde. Magni nesciunt omnis illo optio. Molestias eum exercitationem aut harum odit.",
    "Modi quia laboriosam qui ad aut. Fugit quisquam earum distinctio officia est fugit quos. Iure repellat distinctio veritatis voluptate amet ratione repudiandae.",
    "Impedit perferendis vel quis ratione. Id aut id officia. Illum fuga saepe provident voluptate eligendi provident. Et nostrum maxime magni. Nobis cumque id magni in iste earum.",
    "Dolor numquam maiores praesentium dolorem nihil minus praesentium rem dolorem. Est rerum placeat. Numquam sed magni aliquam laborum enim facere ducimus.",
    "In sint enim nam et itaque et qui. Molestiae a iusto quidem quia temporibus id quia eaque eius. Quis quia consectetur saepe vero et molestias. Rem minima accusamus vitae adipisci molestiae unde voluptate consequatur molestiae. Rerum exercitationem quisquam vitae.",
]

export const faq = [
    {
        question: "What is Flowpoint?",
        answer: "It's a SaaS website analytics software standalone which tracks users on websites, understands how they behave, spots potential conversion problems, and generates technical, content, and UX recommendations to boost conversion rates.",
    },
    {
        question: "How does Flowpoint help optimize website conversion rates?",
        answer: "It analyses users' behaviour, finds issues and where they get stuck, and generates AI data-driven recommendations easy to implement by the marketers or business owners.",
    },
    {
        question: "What types of businesses can benefit from using Flowpoint?",
        answer: "Any types of business, especially B2C companies with large volumes of website traffic where their core business revenue is generated through the website.",
    },
    {
        question: "What features does Flowpoint offer for optimizing conversion rates?",
        answer: "Funnel Analytics, AI Generated website audit & recommendations, Ava: AI ChatBot that can discuss anything about your website data and help you interpret the data, Session tracking: allows you to monitor what each user is doing.",
    },
    {
        question: "How can Flowpoint help improve user engagement on my website?",
        answer: "It can help you by giving you a good overview of where users get stuck on your website such that you can improve their experience and reduce friction.",
    },
    {
        question: "Can Flowpoint help with optimizing both technical and content aspects of my website?",
        answer: "Yes, it can help you identify website errors that are impacting your users conversion rates, UX issues, and content issues which could make your users confused.",
    },
    {
        question: "Can Flowpoint provide AI-powered recommendations for UI/UX improvements?",
        answer: "Yes, it can identify where UX could be the reason for user drop off and generate recommendations.",
    },
    {
        question: "How does Flowpoint generate AI recommendations for optimization?",
        answer: "It monitors how users that are converting behave compared to the ones that are not converting. It then looks at your websites, parses your website pages, visual design, and content to identify if all best practices for website design and performance are followed. If not, we then correlate this with the behaviour and generate potential recommendations. Once we generate them and you implement them, based on the change in conversion rate, we train our model to better generate recommendations that are suitable for your website. At the end of the day, it will be an iterative process. Each recommendation is suggested as an A/B test that you should run and see the outcome.",
    },
    {
        question: "Can Flowpoint track and analyze marketing campaigns for better conversion rates?",
        answer: "Yes, it can automatically capture all the active marketing campaigns from your account.",
    },
    {
        question: "How can Flowpoint help in identifying and prioritizing issues on my website?",
        answer: "Flowpoint observes which issues are causing the most drop off and prioritises them.",
    },
    {
        question: "Can Flowpoint provide automated audits for conversion rate optimization (CRO)?",
        answer: "Yes, Flowpoint provides a flow audit automatically first time you create your flows, then you update the flows you can request to process a new audit.",
    },
    {
        question: "How can Flowpoint help me generate more revenue through website optimization?",
        answer: "Flowpoint helps you optimise conversion rates on your website, for example optimising your product purchasing flows, upselling flows or other revenue generating website flows, thus directly boosting your revenue through your website.",
    },
    {
        question: "Is Flowpoint suitable for businesses of all sizes?",
        answer: "Yes, Flowpoint can accommodate from small businesses with the Free plan to large enterprises through the Enterprise plan which can offer a custom Flowpoint configuration and accommodate the analysis for larger amounts of traffic.",
    },
    {
        question: "Can Flowpoint integrate with other tools or platforms that I'm currently using?",
        answer: "Flowpoint can integrate with any website technology using HTML and Javascript. You just need to copy paste the Flowpoint tracking code into the <head> element of each page of your website and it's up and running.",
    },
    {
        question: "Can Flowpoint provide support and assistance in implementing the recommended optimizations?",
        answer: "Flowpoint is working with several partners to help our customers implement the recommendations we generate. For more information please reach out to support@flowpoint.ai.",
    },
    {
        question: "How can Flowpoint help in reducing bounce rates on my website?",
        answer: "Flowpoint provides recommendations that are designed to enhance the user experience and reduce friction on your website and boost performance, reduce loading times etc, meaning that users will be less likely to bounce quicker.",
    },
    {
        question: "Can Flowpoint help optimize the checkout process for my e-commerce website?",
        answer: "Yes, Flowpoint generates a wide range of recommendations including recommendations specific to the checkout process, suggesting you changes that have been proven to boost conversion rates, but those suggestions will be sent through our AI algorithms to tailor them to your particular use case and users behaviour.",
    },
    {
        question: "Can Flowpoint track user engagement and conversion rates across different marketing channels?",
        answer: "Flowpoint can currently track engagement on multiple websites where you can install the Flowpoint Tracking Code. Unfortunately, it does not have access to data outside the websites where it is installed.",
    },
    {
        question: "Can Flowpoint help identify and optimize high-value user flows on my website?",
        answer: "Flowpoint is currently showing you the activity of all of your users and can help you identify high value users by looking at all the events a user is making, thus for example, identifying users that are interested in several products and are checking their pricing and making purchases often could be considered as high value users. A more automated approach to this process is currently on our roadmap and will be developed and launched soon.",
    },
    {
        question: "Can Flowpoint provide insights on user interactions with specific elements or sections of my website?",
        answer: "Yes, on Flowpoint, you can build something called a user flow, which is a visual representation of a user journey on your website. Within those flows you can define specific elements such as buttons, links, images and more that you are interested in tracking.",
    },
    {
        question: "Can Flowpoint help in identifying and addressing mobile usability issues on my website?",
        answer: "Yes, Flowpoint offers recommendations specific to mobile and desktop devices so you can optimise your users experience on both platforms.",
    },
    {
        question: "Can Flowpoint help with improving website performance and page load speed?",
        answer: "Yes, Flowpoint will detect if a slow page is affecting users conversions and will generate recommendations helping you address the technical issues affecting the speed, such as technical errors or large images or script errors.",
    },
    {
        question: "Can Flowpoint help in analyzing and optimizing user engagement on specific landing pages?",
        answer: "Yes, using the User flows feature, you can define any landing page from your website and track the user engagement and conversion rates accordingly.",
    },
    {
        question: "How does Flowpoint handle sensitive user data and ensure data privacy and security?",
        answer: "Flowpoint is currently employing the highest level of security measures and is encrypting all the data being transferred from the website browser towards the end in the data server. Each user bucket is encapsulated for each user one by one and you are the sole proprietary of your website visitors data. Data is anonymised and stored in a GDPR and CCPA compliant manner. Flowpoint is also an ICO compliant company in the United Kingdom.",
    },
    {
        question: "Can Flowpoint provide insights on user behavior across different devices and browsers?",
        answer: "Yes, Flowpoint offers information about the devices and browsers being used by the website visitors.",
    },
    {
        question: "Can Flowpoint provide recommendations for improving website accessibility and compliance with accessibility standards?",
        answer: "Yes, Flowpoint includes recommendations that refer to accessibility and compliance, however this is limited to issues that are affecting conversion rates.",
    },
    {
        question: "Can Flowpoint help in optimizing user engagement and conversion rates for specific industries or niches?",
        answer: "Flowpoint's algorithms have been trained on data providing from website's from multiple industries, however at the moment it does not provide recommendations specific to any industry and it's solely focusing on the website. More updates will come soon regarding improved website audit tailored to specific industries.",
    },
    {
        question: "Can Flowpoint track and analyze user interactions with specific forms or signup processes on my website?",
        answer: "Yes, Flowpoint can track interactions with forms or sign up processes easily by just configuring click events on the dashboard. For some more advanced interactions, Flowpoint also allows configuration for custom events.",
    },
    {
        question: "Can Flowpoint provide insights on user behavior and conversion rates for different demographic segments?",
        answer: "Unfortunately at the moment, the recommendations are not tailored to any demographic segment, however this features is currently on our roadmap.",
    },
    {
        question:
            "Can Flowpoint provide insights on user engagement and conversion rates for different traffic sources, such as organic search, social media, or referral traffic?",
        answer: "Yes, Flowpoint can track engagement and conversion rates based on traffic sources and can allow you to filter and observe your traffic just based on particular sources, such that you can identify the best performing marketing campaign or referral.",
    },
    {
        question: "Can Flowpoint track and analyze user interactions with dynamic or AJAX-based elements on my website?",
        answer: "Yes, Flowpoint can track interactions with dynamic elements on the website using the click elements on the screen or by using custom events where necessary.",
    },
    {
        question: "What are the pricing options and plans available for Flowpoint?",
        answer: "Flowpoint provides 3 entry level pricing options: Free, Standard at 59$, and Pro at 249$. Additionally, there is an Enterprise Plan which is suitable for larger businesses with more traffic and more demands.",
    },
    {
        question: "What is included in the Flowpoint Free plan?",
        answer: "1k monthly sessions, Custom events, User sessions, Page exploration, Reports, Cross domain tracking, Ava, AI Chatbot to discuss about your web analytics, Funnel Analytics.",
    },
    {
        question: "What is included in the Flowpoint Standard plan?",
        answer: "10k monthly sessions, Issue detection & prioritisation, Advanced Funnel Analytics, Technical recommendations, UI/UX recommendations, Behaviour analysis, Cross-domain funnels, A/B monitoring.",
    },
    {
        question: "What features are included in the Flowpoint PRO plan?",
        answer: "50k monthly sessions, Issue event explorer, Marketing campaigns monitoring, Content recommendations, Early access to latest features.",
    },
    {
        question: "What features and benefits can I expect from the Flowpoint Custom (Enterprise) plan?",
        answer: "From 100k monthly analysed sessions, Cohort analysis, Customer segmentation, Realtime monitoring, Bot detection, Priority customer support, Custom features.",
    },
    {
        question: "Can I upgrade or downgrade my Flowpoint plan at any time?",
        answer: "Yes, straight from the dashboard with just a few clicks.",
    },
    {
        question: "Is there a contract or commitment required when subscribing to Flowpoint?",
        answer: "The minimum commitment is 1 month, you can then cancel your plan at any time, no fees or other charges will be applied.",
    },
    {
        question: "Can I try Flowpoint before committing to a paid subscription?",
        answer: "Yes, there is a 14 day free trial available with no commitments. Cancel at any point without any charges.",
    },
    {
        question: "Can I get technical support and assistance while using Flowpoint?",
        answer: "Yes, we are just a message away on the live chat or you can email us at support@flowpoint.ai.",
    },
    {
        question: "Can I access Flowpoint analytics and reports on mobile devices or only on desktop computers?",
        answer: "Unfortunately there is limited availability on mobile devices at the moment, and we advise you use the desktop to access the Flowpoint dashboard, however more support for mobile devices will be added soon.",
    },
    {
        question: "Can Flowpoint be integrated with other analytics platforms or tools that I currently use?",
        answer: "Unfortunately not at the moment. Flowpoint is a standalone web analytics tool and has its own tracking code and database. However integrating with other web analytics tool is something we have on our roadmap.",
    },
    {
        question: "How often does Flowpoint update its AI algorithms and add new features?",
        answer: "Flowpoint updates the AI algorithm almost every 2 weeks to enhance the results it generates and improve your experience using our software.",
    },
    {
        question: "How secure is the data stored and processed by Flowpoint?",
        answer: "The data is stored using Google Cloud Services, currently the data centers are based in London. Security is our main priority when dealing with sensitive user data, therefore we have data backups, data is encrypted and using the latest standards in terms of encryption and data communication between the client and the server. Everything is GDPR and CCPA compliant and tested.",
    },
    {
        question: "Can Flowpoint be used for e-commerce websites or is it only suitable for specific industries?",
        answer: "Yes, it can be used for any website especially e-commerce websites.",
    },
    {
        question: "Can Flowpoint track user interactions and behavior across multiple domains or websites owned by the same organization?",
        answer: "Yes, it can track interactions across any website as long as the tracking code has been installed on each website (for example: www.blog.flowpoint.ai and www.flowpoint.ai can both be tracked on the same account and users navigating from one domain to the other will be identified and matched in the user flows).",
    },
]

export const faqPartners = [
    {
        id: 1,
        question: "How much could I earn?",
        answer: "Flowpoint Offers 25% Commissions Monthly—Join Our Affiliate Program Now:",
        list: [
            "Bring 10 clients and make $7,470 per year",
            "Bring 50 clients and make $37,350 per year",
            "Bring 75 clients and make $56,025 per year",
        ],
    },

    {
        id: 2,
        question: "Who should use the program?",
        answer: "The partner program is open to anyone who recommends and uses Flowpoint to help their clients provide better product experiences. Plus points if you're a Flowpoint advocate, ready to encourage its use to achieve exponential growth.",
    },
    {
        id: 3,
        question: "When do the payments stop?",
        answer: "We'll stop paying you if the customer you've referred stops using Flowpoint, or if you leave the Partner Program. If your client no longer works with you but continues to use a Flowpoint paying subscription, you will keep receiving your commission..",
    },
]

export const faqCompetitor = [
    {
        id: 1,
        question: "How does Flowpoint handle data privacy and is it GDPR compliant?",
        answer: "At Flowpoint, we prioritize data privacy and ensure full compliance with GDPR regulations. We implement stringent security measures to safeguard your data. Each customer’s data is hosted in encapsulated buckets, accessible only to the respective owner. We do not share any customer data with third parties. ",
        answer2:
            "Our platform employs techniques to suppress sensitive data, ensuring that no sensitive information is stored on Flowpoint’s servers unless specifically requested or integrated through custom events and attributes. This approach adds an extra layer of protection to sensitive data. We take privacy seriously and strive to provide a secure and GDPR-compliant environment for our users.",
    },
    {
        id: 2,
        question: "What are the pricing options for Flowpoint?",
        answer: "Flowpoint offers three pricing plans tailored to meet different needs. ",
        list: [
            "Our STARTER plan is available for £49 and provides essential analytics and recommendations. ",
            "The PRO plan, priced at £199, offers advanced features and deeper insights. We also provide a custom plan that offers full control over your data, along with custom features developed specifically for your requirements.",
            "Please contact us for a personalized quote and more details on our custom plan.",
        ],
    },
    {
        id: 3,
        question: "How does Flowpoint’s widget performance affect my website?",
        answer: "Flowpoint’s widget is designed with a proprietary lightweight approach to minimize any impact on your website’s performance. The widget captures only relevant data in text format, ensuring efficient data collection. It sends data asynchronously to our backend, meaning it doesn’t block other website elements or hinder the rendering process. ",
        answer2:
            "Flowpoint is loaded asynchronously, in parallel with other elements on the screen, resulting in a seamless user experience without any noticeable delays. Rest assured that our widget is optimized for performance, allowing you to gain valuable insights without compromising your website’s speed or user experience.",
    },
]
