import { PlanFreeIcon, PlanStarterIcon, PlanPremiumIcon } from "../assets"

// ----------------------------------------------------------------------

const LICENSES = ["Standard", "Standard Plus", "Extended"]

export const features = {
    sessionsFeature: (limit) => ({
        text: `${limit} sessions/month`,
        description: `Analyze a maximum of ${limit} sessions each month.`,
    }),
    userSessions: { text: "User Sessions", description: "See detailed user session data." },
    pageExploration: { text: "Page Exploration (10 pages)", description: "A bird's eye view of your entire website traffic." },
    advancedPageExploration: { text: "Advanced Page Exploration", description: "Advanced page exploration capabilities." },
    reports: { text: "Reports", description: "Detailed website analytics reports." },
    unlimitedReports: { text: "Unlimited Reports", description: "Unlimited access to reports for all your data." },
    dailyMarketingInsights: {
        text: "3 Daily Marketing insights (Coming soon)",
        description: "Insights to help improve your marketing daily.",
    },
    unlimitedMarketingInsights: { text: "Unlimited Daily Marketing Insights", description: "Unlimited insights to enhance marketing." },
    dataAnalysisSpeed: { text: "Data Analysis Speed", description: "Faster data analysis." },
    autoFlowInsights: { text: "Auto Flow Insights", description: "Automated insights on user flows." },
    marketingCampaigns: { text: "Marketing Campaigns", description: "Monitor and analyze marketing campaigns." },
    teamMembers: { text: "Team Members", description: "Manage multiple team members." },
    advancedIssueExploration: {
        text: "Advanced Behavior Issue Exploration",
        description: "Advanced tools for exploring user behavior issues.",
    },
    crossDomainTracking: { text: "Cross Domain Tracking", description: "Track users across multiple domains." },
    earlyAccess: { text: "Early Access to Latest Features", description: "Get access to the latest features early." },
    prioritySupport: { text: "Priority Support", description: "Receive priority support via chat and email." },
    customEvents: { text: "Custom Events", description: "Monitor custom user actions and attributes." },
    aiAssistant: { text: "Ava Assistant powered by ChatGPT", description: "AI assistant powered by ChatGPT." },
    dedicatedCustomerManager: {
        text: "Dedicated Customer Experience Manager",
        description: "Get a dedicated manager for customer experience.",
    },
    FeaturePreviews: {
        text: " Feature Previews",
    },
    DedicatedSupportViaChatEmailVideo: { text: "Dedicated Support via Chat, Email and Video" },
    OnPremisesDeployment: { text: "On-Premises Deployment" },
}

export const _homePlans = [...Array(3)].map((_, index) => ({
    license: LICENSES[index],
    commons: ["One end products", "12 months updates", "6 months of support"],
    options: ["JavaScript version", "TypeScript version", "Design Resources", "Commercial applications"],
    icons: [
        "https://minimal-assets-api.vercel.app/assets/images/home/ic_sketch.svg",
        "https://minimal-assets-api.vercel.app/assets/images/home/ic_figma.svg",
        "https://minimal-assets-api.vercel.app/assets/images/home/ic_js.svg",
        "https://minimal-assets-api.vercel.app/assets/images/home/ic_ts.svg",
    ],
}))

export const _pricingPlans = [
    {
        subscription: "free",
        // icon: <PlanFreeIcon />,
        price: 0,
        currency: "usd",
        caption: "Best for small websites",

        lists: [
            { isAvailable: true, ...features.sessionsFeature("1K") },
            { isAvailable: true, ...features.userSessions },
            { isAvailable: true, ...features.pageExploration },
            { isAvailable: true, ...features.flows, text: "5 User Flows" },
            { isAvailable: true, ...features.reports },
            { isAvailable: true, ...features.dailyMarketingInsights },
            { isAvailable: true, ...features.aiAssistant },
            { isAvailable: true, ...features.teamMembers, text: "Team members: 1" },
            { isAvailable: true, ...features.dataAnalysisSpeed, text: "1x Data Analysis Speed" },
        ],
        labelAction: "Try for free",
        to: "https://dashboard.flowpoint.ai/auth/register?membershipPlan=lite&period=monthly",
    },
    {
        subscription: "standard",
        // icon: <PlanStarterIcon />,
        price: 59,
        currency: "usd",
        caption: "Best for websites with growing traffic",
        lists: [
            { isAvailable: true, ...features.sessionsFeature("10K") },
            { isAvailable: true, ...features.flows, text: "10 User Flows" },
            { isAvailable: true, ...features.advancedPageExploration, text: "Advanced Page Exploration (30 pages)" },

            { isAvailable: true, ...features.dailyMarketingInsights, text: "Unlimited Daily Marketing Insights" },
            { isAvailable: true, ...features.dataAnalysisSpeed, text: "2x Data Analysis Speed" },
            { isAvailable: true, ...features.autoFlowInsights, text: "3 Auto Flow Insights" },
            { isAvailable: true, ...features.teamMembers, text: "Team members: unlimited" },
            { isAvailable: true, ...features.teamMembers, text: "Standard support" },
        ],
        labelAction: "Try for free",
        to: "https://dashboard.flowpoint.ai/auth/register?membershipPlan=starter&period=monthly",
    },
    {
        subscription: "pro",
        // icon: <PlanStarterIcon />,
        price: 249,
        sessionsAmount: [
            50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 200, 250, 300, 400, 500, 1000, 2000, 5000, 10000, 12000, 15000, 20000,
        ].map((elem) => {
            if (elem >= 1000) {
                return {
                    amount: elem * 1000,
                    label: `${elem / 1000}M sessions`,
                }
            } else {
                return {
                    amount: elem * 1000,
                    label: `${elem}k sessions`,
                }
            }
        }),
        currency: "usd",
        caption: "Best for e-commerce and B2B websites",
        lists: [
            { isAvailable: true, ...features.advancedPageExploration, text: "Advanced Page Exploration (unlimited pages)" },
            { isAvailable: true, ...features.flows, text: "Unlimited User Flows" },
            { isAvailable: true, ...features.dailyMarketingInsights, text: "Unlimited Daily Marketing and UX Insights" },
            { isAvailable: true, ...features.dataAnalysisSpeed, text: "5x Data Analysis Speed" },
            { isAvailable: true, ...features.autoFlowInsights, text: "Unlimited Auto Flow Insights" },
            { isAvailable: true, ...features.marketingCampaigns },
            { isAvailable: true, ...features.advancedIssueExploration },
            { isAvailable: true, ...features.customEvents },
            { isAvailable: true, ...features.crossDomainTracking },
            { isAvailable: true, ...features.earlyAccess },
            { isAvailable: true, ...features.prioritySupport, text: "Priority Support via Chat and Email" },
        ],
        labelAction: "Try for free",
        to: "https://dashboard.flowpoint.ai/auth/register?membershipPlan=pro&period=monthly",
    },
    {
        subscription: "enterprise",
        // icon: <PlanPremiumIcon />,
        price: null,
        caption: "Best for organizations with advanced security and management needs",
        description: "Slide to configure your monthly plan",
        descriptionYearly: "Slide to configure your pre purchase bucket",
        lists: [
            { isAvailable: true, ...features.OnPremisesDeployment, text: "On-Premises Deployment" },
            { isAvailable: true, ...features.FeaturePreviews, text: "Feature Previews" },
            { isAvailable: true, ...features.dedicatedCustomerManager },
            { isAvailable: true, ...features.DedicatedSupportViaChatEmailVideo, text: "Dedicated Support via Chat, Email and Video" },
        ],
        labelAction: "Talk to sales",
        to: "https://calendly.com/flowpoint-vlad/intro",
    },
]

export const _partnerPlans = [
    {
        subscription: "SILVER",
        // icon: <PlanFreeIcon />,
        price: 10,

        caption: "up to 100k sessions analysed per month in total",
        captionYearly: "up to 100k sessions analysed per month in total",
        lists: [
            { text: "10% commission", isAvailable: true },
            { text: "Onboarding support", isAvailable: true },
            { text: "Technical support", isAvailable: true },
            { text: "Sales support", isAvailable: true },
        ],
        labelAction: "Get started",
        to: "https://dashboard.flowpoint.ai/auth/register?membershipPlan=free&period=monthly",
    },
    {
        subscription: "GOLD",
        // icon: <PlanStarterIcon />,
        price: 15,
        caption: "100k - 1M sessions analysed per month in total",
        captionYearly: "100k - 1M sessions analysed per month in total",
        description: "14-day free trial",
        lists: [
            { text: "15% commission", isAvailable: true },
            { text: "Onboarding support", isAvailable: true },
            { text: "Technical support", isAvailable: true },
            { text: "Sales support", isAvailable: true },
            { text: "Ul Customisation", isAvailable: true },
        ],
        labelAction: "Get started",
        to: "https://dashboard.flowpoint.ai/auth/register?membershipPlan=starter&period=monthly",
    },
    {
        subscription: "DIAMOND",
        // icon: <PlanStarterIcon />,
        price: 25,
        caption: "1M sessions analysed per month in total",
        captionYearly: "1M sessions analysed per month in total",
        lists: [
            { text: "25% commission", isAvailable: true },
            { text: "Onboarding support", isAvailable: true },
            { text: "Technical support", isAvailable: true },
            { text: "Sales support", isAvailable: true },
            { text: "UI Customisation", isAvailable: true },
            { text: "Custom features", isAvailable: true },
        ],
        labelAction: "Get started",
        to: "https://dashboard.flowpoint.ai/auth/register?membershipPlan=pro&period=monthly",
    },
]
